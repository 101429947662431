import PropTypes from "prop-types";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default class Video extends React.Component {
  render() {
    const video = this.props.video;
    return (
      <Container fluid>
        <Row>
          <Col sm="auto">
            <img src={video.thumbnails.medium.url} width="168" />
          </Col>
          <Col>
            <h5>{video.title}</h5>
            <p>{video.channelTitle}</p>
          </Col>
        </Row>
      </Container>
    );
  }
}

Video.propTypes = {
  videoId: PropTypes.string.isRequired,
  video: PropTypes.object.isRequired
};
