import React from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";

const styles = {
  center: {
    textAlign: "center"
  }
};

class NotFoundPage extends React.Component {
  render() {
    return (
      <Container fluid style={styles.center}>
        <p style={{ fontSize: "5rem" }}>404</p>
        <p style={{ fontSize: "2rem" }}>We couldn't find this page.</p>
      </Container>
    );
  }
}

export default withRouter(NotFoundPage);
