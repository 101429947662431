import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";

export default class VideoIdSerach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputRef: React.createRef(),
      url: this._paramsToUrl(props) || ""
    };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.videoId !== this.props.videoId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ url: this._paramsToUrl(this.props) || "" });
    }
  }
  _paramsToUrl = ({ videoId, listId }) => {
    if (videoId) {
      if (listId) {
        return `https://www.youtube.com/watch?v=${videoId}&list=${listId}`;
      } else {
        return `https://www.youtube.com/watch?v=${videoId}`;
      }
    } else if (listId) {
      return `https://www.youtube.com/playlist?list=${listId}`;
    } else {
      return null;
    }
  };
  _urlToParams = url => {
    var match = /^https?:\/\/(?:www\.)?youtube\.com\/watch\?(.*)$/.exec(url);
    if (match) {
      const queries = match[1].split("&");
      const videoIdQuery = queries.find(x => x.startsWith("v="));
      const listIdQuery = queries.find(x => x.startsWith("list="));
      const videoId = videoIdQuery && videoIdQuery.substr(2);
      const listId = listIdQuery && listIdQuery.substr(5);
      return { videoId, listId };
    }
    match = /^https?:\/\/(?:www\.)?youtube\.com\/playlist\?(.*)$/.exec(url);
    if (match) {
      const queries = match[1].split("&");
      const listIdQuery = queries.find(x => x.startsWith("list="));
      const listId = listIdQuery && listIdQuery.substr(5);
      return { listId };
    }
    return null;
  };
  _isValidURL = url => {
    return this._urlToParams(url) != null;
  };
  _searchWithParams = params => {
    if (this.props.onSearch !== null) {
      this.props.onSearch(params);
    }
  };
  _copyToClipboard = () => {
    const dom = this.state.inputRef.current;
    if (dom === null) {
      return;
    }
    dom.select();
    document.execCommand("copy");
  };
  render() {
    const { videoId: _v, onSearch: _s, ...props } = this.props;
    return (
      <InputGroup {...props}>
        <FormControl
          ref={this.state.inputRef}
          placeholder="YouTube Video URL"
          value={this.state.url}
          isValid={this._isValidURL(this.state.url)}
          onChange={e => this.setState({ url: e.target.value })}
        />
        <InputGroup.Append>
          <Button variant="outline-secondary" onClick={this._copyToClipboard}>
            <FontAwesomeIcon icon={faCopy} />
          </Button>
          <Button
            variant="outline-primary"
            onClick={() =>
              this._searchWithParams(this._urlToParams(this.state.url))
            }
          >
            Go
          </Button>
        </InputGroup.Append>
      </InputGroup>
    );
  }
}

VideoIdSerach.propTypes = {
  videoId: PropTypes.string,
  listId: PropTypes.string,
  onSearch: PropTypes.func
};
