import PropTypes from "prop-types";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import uuid from "../libraries/uuid";

const iframeAPIEventTarget = document;
var iframeAPIReady = false;

var tag = document.createElement("script");
tag.src = "https://www.youtube.com/iframe_api";
document.body.appendChild(tag);

global.onYouTubeIframeAPIReady = function() {
  iframeAPIReady = true;
  iframeAPIEventTarget.dispatchEvent(new Event("load"));
};

const styles = {
  container: {
    wordBreak: "break-word"
  },
  player: {},
  avatar: {
    verticalAlign: "middle",
    width: 48,
    height: 48,
    borderRadius: "50%"
  }
};

export default class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playerId: uuid(),
      player: null
    };
  }
  componentDidMount() {
    iframeAPIEventTarget.addEventListener("load", this._onIframeReady);
    if (iframeAPIReady) {
      this._onIframeReady();
    }
  }
  componentDidUpdate(prevProps) {
    if (this.state.player == null) {
      return;
    }
    if (prevProps.videoId !== this.props.videoId) {
      this.state.player.loadVideoById(this.props.videoId);
    }
    if (prevProps.isPlaying !== this.props.isPlaying) {
      if (this.props.isPlaying) {
        this.state.player.playVideo();
      } else {
        this.state.player.pauseVideo();
      }
    }
  }
  componentWillUnmount() {
    iframeAPIEventTarget.removeEventListener("load", this._onIframeReady);
  }
  _onIframeReady = () => {
    const options = {
      videoId: this.props.videoId,
      width: "100%",
      playerVars: {
        autoplay: this.props.isPlaying
      },
      events: {
        onReady: this._onPlayerReady,
        onStateChange: this._onPlayerStateChange,
        onError: this._onPlayerError
      }
    };
    this.setState(prevState => ({
      player: new YT.Player(prevState.playerId, options)
    }));
  };
  _onPlayerReady = () => {
    (this.props.onPlayerReady || (() => {}))();
  };
  _onPlayerStateChange = event => {
    switch (event.data) {
      case YT.PlayerState.ENDED:
        (this.props.onPlayerStateChange || (() => {}))(Player.State.ENDED);
        break;
      case YT.PlayerState.PLAYING:
        (this.props.onPlayerStateChange || (() => {}))(Player.State.PLAYING);
        break;
      case YT.PlayerState.PAUSED:
        (this.props.onPlayerStateChange || (() => {}))(Player.State.PAUSED);
        break;
      case YT.PlayerState.BUFFERING:
        (this.props.onPlayerStateChange || (() => {}))(Player.State.BUFFERING);
        break;
      case YT.PlayerState.CUED:
        (this.props.onPlayerStateChange || (() => {}))(Player.State.CUED);
    }
  };
  _onPlayerError = event => {
    const onPlayerError = this.props.onPlayerError || (() => {});
    switch (event.data) {
      case 2:
        onPlayerError(Player.Error.INVALID_ARGUMENTS);
        break;
      case 5:
        onPlayerError(Player.Error.PLAYER_ERROR);
        break;
      case 100:
        onPlayerError(Player.Error.CONTENT_NOT_FOUND);
        break;
      case 101:
      case 150:
        onPlayerError(Player.Error.FORBIDDEN);
        break;
      default:
        onPlayerError(Player.Error.UNKNOWN);
    }
  };
  render() {
    const video = this.props.video;
    const channel = this.props.channel;
    return (
      <Container fluid style={styles.container}>
        <Row>
          <div id={this.state.playerId} style={styles.player} />
        </Row>
        <Row>
          <h4>{video.title}</h4>
        </Row>
        {channel && (
          <Row>
            <Col sm="auto">
              <img style={styles.avatar} src={channel.thumbnails.default.url} />
            </Col>
            <Col>
              <h6>{channel.title}</h6>
              <p>{channel.description}</p>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

Player.propTypes = {
  videoId: PropTypes.string.isRequired,
  channel: PropTypes.object,
  video: PropTypes.object.isRequired,
  isPlaying: PropTypes.bool,
  onPlayerReady: PropTypes.func,
  onPlayerStateChange: PropTypes.func,
  onPlayerError: PropTypes.func
};

Player.defaultProps = {
  isPlaying: false
};

Player.State = {
  ENDED: 0,
  PLAYING: 1,
  PAUSED: 2,
  BUFFERING: 3,
  CUED: 4
};

Player.Error = {
  INVALID_ARGUMENTS: 0,
  PLAYER_ERROR: 1,
  CONTENT_NOT_FOUND: 2,
  FORBIDDEN: 3,
  UNKNOWN: 4
};
