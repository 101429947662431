import React from "react";
import { Container, Alert } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import { Player, VideoIdSearch, SearchResult } from "../components";
import { ytapi, location, yresource } from "../libraries";

/// location: /watch?v=${videoId}
class WatchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      video: null,
      channel: null,
      relatedVideos: null,
      relatedNextPageToken: null,
      playlistId: null,
      playlistItems: null,
      playlistNextPageToken: null
    };
  }
  componentDidMount() {
    const { v: videoId, list: listId } = location.queries(this.props.location);
    this._loadVideo({ videoId, listId });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      const { v: videoId, list: listId } = location.queries(
        this.props.location
      );
      this._loadVideo({ videoId, listId });
    }
  }
  _toNext = async () => {
    var nextVideo = null;
    if (Array.isArray(this.state.relatedVideos)) {
      if (this.state.relatedVideos.length === 0) {
        return;
      }
      nextVideo = this.state.relatedVideos[0];
      this.setState(prevState => ({
        relatedVideos: Array.isArray(prevState.relatedVideos)
          ? prevState.relatedVideos.slice(1)
          : null
      }));
    } else if (Array.isArray(this.state.playlistItems)) {
      if (this.state.playlistItems.length === 0) {
        return;
      }
      nextVideo = this.state.playlistItems[0];
      this.setState(prevState => ({
        playlistItems: Array.isArray(prevState.playlistItems)
          ? prevState.playlistItems.slice(1)
          : null
      }));
    } else {
      return;
    }

    const params = yresource(nextVideo);
    try {
      const { video, channel } = await this._getVideoInfo(params.videoId);
      this.setState({ alert: null, video, channel });
      if (
        Array.isArray(this.state.relatedVideos) &&
        this.state.relatedVideos.length === 0
      ) {
        this._loadNextRelatedVideo();
      } else if (
        Array.isArray(this.state.playlistItems) &&
        this.state.playlistItems.length === 0
      ) {
        this._loadPlaylistItems();
      }
    } catch (error) {
      console.error(error);
      this.setState({ alert: "Failed to load the video" });
    }
  };
  _onStateChange = state => {
    if (state === Player.State.ENDED) {
      this._toNext();
    }
  };
  _onError = error => {
    console.error("Failed to play", error);
    this._toNext();
  };
  _getVideoInfo = async videoId => {
    const {
      data: {
        items: [videoItem, ..._v]
      }
    } = await ytapi.get("/v3/videos", {
      params: {
        part: "snippet",
        id: videoId
      }
    });
    const {
      data: {
        items: [channelItem, ..._c]
      }
    } = await ytapi.get("/v3/channels", {
      params: {
        part: "snippet",
        id: videoItem.snippet.channelId
      }
    });
    return { video: videoItem, channel: channelItem };
  };
  _loadVideo = async params => {
    try {
      this.setState({ alert: null });

      var videoId = params.videoId;
      if (params.listId) {
        await this._loadPlaylistItems(params.listId);
        if (!videoId) {
          await this._toNext();
          return;
        }
      }

      if (params.videoId) {
        const { video, channel } = await this._getVideoInfo(params.videoId);
        this.setState({ video, channel });
        if (!params.listId) {
          this._loadRelatedVideo(video.snippet.title);
        }
      }
    } catch (error) {
      console.error(error);
      this.setState({ alert: "Failed to load the video" });
    }
  };
  _loadPlaylistItems = async playlistId => {
    try {
      const {
        data: { nextPageToken, items }
      } = await ytapi.get("/v3/playlistItems", {
        params: {
          part: "snippet",
          playlistId
        }
      });
      this.setState({
        relatedToVideoId: null,
        relatedVideos: null,
        relatedNextPageToken: null,
        playlistId,
        playlistItems: items,
        playlistNextPageToken: nextPageToken
      });
    } catch (error) {
      console.error(error);
    }
  };
  _loadRelatedVideo = async title => {
    try {
      const {
        data: { nextPageToken, items }
      } = await ytapi.get("/v3/search", {
        params: {
          part: "snippet",
          q: title,
          order: "relevance",
          type: "video"
        }
      });
      this.setState(prevState => ({
        relatedVideos: items.filter(x => x.id.videoId !== prevState.video.id),
        relatedNextPageToken: nextPageToken,
        playlistId: null,
        playlistItems: null,
        playlistNextPageToken: null
      }));
    } catch (error) {
      console.error(error);
    }
  };
  _loadNextPlaylistItems = async () => {
    try {
      const {
        data: { nextPageToken, items }
      } = await ytapi.get("/v3/playlistItems", {
        params: {
          part: "snippet",
          playlistId: this.state.playlistId,
          pageToken: this.state.nextPageToken
        }
      });
      this.setState(prevState => ({
        playlistItems: prevState.playlistItems.concat(
          items.filter(x => x.id.videoId !== prevState.video.id)
        ),
        playlistNextPageToken: nextPageToken
      }));
    } catch (error) {
      console.error(error);
    }
  };
  _loadNextRelatedVideo = async () => {
    try {
      const {
        data: { nextPageToken, items }
      } = await ytapi.get("/v3/search", {
        params: {
          part: "snippet",
          order: "relevance",
          type: "video",
          q: this.state.video.snippet.title,
          pageToken: this.state.relatedNextPageToken
        }
      });
      this.setState(prevState => ({
        relatedVideos: prevState.relatedVideos.concat(
          items.filter(x => x.id.videoId !== prevState.video.id)
        ),
        relatedNextPageToken: nextPageToken
      }));
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    const videoId = this.state.video !== null ? this.state.video.id : null;
    return (
      <Container>
        <VideoIdSearch
          className="mb-3"
          videoId={videoId}
          onSearch={this._loadVideo}
        />
        {this.state.alert && (
          <Alert
            className="mb-3"
            variant="danger"
            onClose={() => this.setState({ alert: null })}
            dismissible
          >
            {this.state.alert}
          </Alert>
        )}
        {this.state.video && (
          <Player
            videoId={this.state.video.id}
            channel={
              this.state.channel !== null ? this.state.channel.snippet : null
            }
            video={this.state.video.snippet}
            isPlaying
            onPlayerStateChange={this._onStateChange}
            onPlayerError={this._onError}
          />
        )}
        {this.state.relatedVideos && (
          <SearchResult
            items={this.state.relatedVideos}
            hasMore={this.state.relatedNextPageToken !== null}
            onMore={this._loadNextRelatedVideo}
          />
        )}
        {this.state.playlistItems && (
          <SearchResult
            items={this.state.playlistItems}
            hasMore={this.state.playlistNextPageToken !== null}
            onMore={this._loadNextPlaylistItems}
          />
        )}
      </Container>
    );
  }
}

export default withRouter(WatchPage);
