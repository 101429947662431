import axios from "axios";

const api = axios.create({
  baseURL: "https://www.googleapis.com/youtube/",
  timeout: 1000
});
api.interceptors.request.use(config => {
  config.params = config.params || {};
  config.params["key"] = __API_KEY__;
  return config;
});

export default api;
