import React from "react";
import { Navbar } from "react-bootstrap";
import {
  withRouter,
  BrowserRouter,
  Route,
  Switch,
  Link
} from "react-router-dom";

import { KeywordSearch } from "./components";
import IndexPage from "./pages/IndexPage";
import NotFoundPage from "./pages/NotFoundPage";
import WatchPage from "./pages/WatchPage";

const styles = {
  container: {
    marginTop: 80,
    marginBottom: 20
  }
};

class App extends React.Component {
  _searchKeyword = keyword => {
    this.props.history.push(`/?q=${encodeURIComponent(keyword)}`);
  };
  render() {
    return (
      <div>
        <Navbar bg="dark" variant="dark" fixed="top">
          <Navbar.Brand>
            <Link to="/">Yotuko</Link>
          </Navbar.Brand>
          <Navbar.Collapse>
            <div className="ml-auto">
              <KeywordSearch
                className="mr-sm-2"
                onSearch={this._searchKeyword}
              />
            </div>
          </Navbar.Collapse>
        </Navbar>
        <div style={styles.container}>
          <Switch style={styles.container}>
            <Route exact path="/" component={IndexPage} />
            <Route path="/watch" component={WatchPage} />
            <Route path="*" status={404} component={NotFoundPage} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default class extends React.Component {
  render() {
    const Ap = withRouter(App);
    return (
      <BrowserRouter>
        <Ap />
      </BrowserRouter>
    );
  }
}
