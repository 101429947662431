import React from "react";
import { Container } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import { SearchResult } from "../components";
import { ytapi, location } from "../libraries";

class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    const { q: keyword } = location.queries(this.props.location);
    this.state = {
      alert: null,
      keyword: keyword || "",
      nextPageToken: null,
      items: []
    };
  }
  componentDidMount() {
    this._searchWithKeyword(this.state.keyword);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      const { q: keyword } = location.queries(this.props.location);
      if (keyword !== null) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ keyword, nextPageToken: null });
        this._searchWithKeyword(keyword);
      }
    }
  }
  _searchWithKeyword = async keyword => {
    try {
      const {
        data: { nextPageToken, items }
      } = await ytapi.get("/v3/search", {
        params: {
          part: "snippet",
          q: keyword,
          order: "relevance",
          type: "video"
        }
      });
      this.setState({ alert: null, nextPageToken, items });
    } catch (error) {
      console.error(error);
      this.setState({ alert: "Failed to search" });
    }
  };
  _searchNext = async () => {
    try {
      const {
        data: { nextPageToken, items }
      } = await ytapi.get("/v3/search", {
        params: {
          part: "snippet",
          q: this.state.keyword,
          order: "relevance",
          type: "video",
          pageToken: this.state.nextPageToken
        }
      });
      this.setState(prevState => ({
        alert: null,
        nextPageToken,
        items: prevState.items.concat(items)
      }));
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    return (
      <Container>
        <SearchResult
          items={this.state.items}
          hasMore={this.state.nextPageToken !== null}
          onMore={this._searchNext}
        />
      </Container>
    );
  }
}

export default withRouter(IndexPage);
