import PropTypes from "prop-types";
import React from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

import { yresource } from "../libraries";
import Video from "./Video";

const styles = {
  center: {
    display: "flex",
    justifyContent: "center"
  },
  ul: {
    listStyleType: "none",
    padding: "5px 20px"
  },
  li: {
    marginBottom: 20
  },
  loading: {
    margin: "auto 20px",
    fontSize: "1rem",
    verticalAlign: "super"
  }
};

export default class SearchResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoadingRef: React.createRef(), isLoading: false };
  }
  componentDidMount() {
    window.addEventListener("scroll", this._onScroll);
    this._onScroll();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this._onScroll);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isLoading: false });
    }
    this._onScroll();
  }
  _onScroll = () => {
    if (this.state.isLoadingRef.current == null || this.state.isLoading) {
      return;
    }
    const rect = this.state.isLoadingRef.current.getBoundingClientRect();
    if (rect.bottom <= window.innerHeight) {
      this.props.onMore && this.props.onMore();
      this.setState({ isLoading: true });
    }
  };
  render() {
    return (
      <div>
        <ul style={styles.ul}>
          {this.props.items.map((item, index) => {
            const params = yresource(item);
            return (
              params &&
              params.snippet && (
                <li key={`${index}_${params.videoId}`} style={styles.li}>
                  <Link to={`/watch?v=${params.videoId}`}>
                    <Video videoId={params.videoId} video={params.snippet} />
                  </Link>
                </li>
              )
            );
          })}
        </ul>
        {this.props.hasMore && !this.state.isLoading && (
          <div ref={this.state.isLoadingRef} />
        )}
        {this.state.isLoading && (
          <div style={styles.center}>
            <Spinner animation="border" role="status" as="span" />
            <span style={styles.loading}>Loading...</span>
          </div>
        )}
      </div>
    );
  }
}

SearchResult.propTypes = {
  items: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  onMore: PropTypes.func
};

SearchResult.defaultProps = {
  items: [],
  hasMore: false
};
