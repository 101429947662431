function queries(location) {
  const { search } = location;
  if (search === null || search.length === 0) {
    return {};
  }
  if (search[0] !== "?") {
    return {};
  }

  var queries = {};
  search
    .slice(1)
    .split("&")
    .reverse()
    .forEach(query => {
      const parts = query.split("=");
      if (parts.length === 0) {
        return;
      }
      queries[parts[0]] =
        parts.length >= 2 ? decodeURIComponent(parts[1]) : true;
    });
  return queries;
}

export default { queries };
