export default function yresource(item) {
  switch (item.kind) {
    case "youtube#playlistItem":
      return {
        videoId: item.snippet.resourceId.videoId,
        snippet: item.snippet
      };
    case "youtube#searchResult":
      return { videoId: item.id.videoId, snippet: item.snippet };
    default:
      return null;
  }
}
