import PropTypes from "prop-types";
import React from "react";
import { Button, InputGroup, FormControl } from "react-bootstrap";

export default class KeywordSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { keyword: props.keyword };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.keyword !== this.props.keyword) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ keyword: this.props.keyword });
    }
  }
  _searchWithKeyword = async keyword => {
    if (this.props.onSearch !== null) {
      this.props.onSearch(keyword);
    }
  };
  render() {
    const { keyword: _k, onSearch: _s, ...props } = this.props;
    return (
      <InputGroup {...props}>
        <FormControl
          value={this.state.keyword}
          placeholder="Keywords"
          onChange={e => this.setState({ keyword: e.target.value })}
        />
        <InputGroup.Append>
          <Button
            variant="outline-primary"
            onClick={() => this._searchWithKeyword(this.state.keyword)}
          >
            Search
          </Button>
        </InputGroup.Append>
      </InputGroup>
    );
  }
}

KeywordSearch.propTypes = {
  keyword: PropTypes.string,
  onSearch: PropTypes.func
};

KeywordSearch.defaultProps = {
  keyword: ""
};
